import React from "react";
import './diamond.scss';


function Diamond(){
    return(
       
    <div className="dip">


        <div className="dipp">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/35mEUX6hNQM?si=VAfNFeQONyI775nK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <div><a href="https://camemorial.org/honor-roll/tribute/shaun-diamond/">PLEASE DONATE TO OFFICER DIAMONDS CHARITY</a></div>

            <h1 className="dih">
                Officer Shaun Diamond: A Legacy of Bravery and Sacrifice
            </h1>

            <p>
                Are you looking for a career that demands courage, dedication, and a commitment to protecting your community? Look no further than the inspiring story of Officer Shaun Diamond of the Pomona Police Department.
            </p>

            <p>
                The Ultimate Sacrifice for Justice

                On October 29, 2014, Officer Diamond's life came to a tragic end while bravely serving his community. Engaged in a high-risk operation targeting criminal motorcycle gangs, Officer Diamond faced gunfire while executing a search warrant. Despite the danger, he selflessly put himself in harm's way to protect others. His sacrifice reminds us of the real dangers officers face daily and the unwavering bravery required to confront them.   
            </p>

            <p>
                A Career of Service and Honor

                Officer Diamond was not just a cop; he was a dedicated law enforcement veteran with 16 years of service. Starting his career with the Los Angeles Police Department before joining the Pomona Police Department in 2006, he quickly became a vital member of the SWAT team in 2008. His commitment to public safety and his willingness to confront danger head-on exemplify the highest ideals of police service.
            </p>

            <p>
                A Mother's Tribute: A Call to Duty

                In the words of Officer Diamond's mother, his legacy continues to inspire and resonate: "You were always my Hero. I love you and Miss you more every day." Her heartfelt message underscores the profound impact Officer Diamond had not only on his family but on his entire community. His dedication to duty, even at the cost of his own life, speaks volumes about the honor and integrity of police work.
            </p>

            <p>
                Choosing a Path of Strength and Justice

                Joining the police force isn't just about upholding the law; it's about embodying the values of courage, integrity, and selflessness that Officer Diamond exemplified. It's about standing tall in the face of danger and protecting those who cannot protect themselves. It's about making a difference every day, just as Officer Diamond did until his final moments.
            </p>

            <p>
                The Truth About Criminals: Weakness Revealed

                In the line of duty, officers like Officer Diamond confront criminals who often try to portray themselves as victims when justice catches up with them. The truth is, those who prey on society are ultimately exposed not just by the law, but by their own inner weaknesses. Joining the police means standing firm against those who would harm others and holding them accountable for their actions.
            </p>

            <p>
                Your Future in Law Enforcement

                If you're considering a career in law enforcement, let Officer Shaun Diamond's story guide your decision. Honor his memory by stepping up to protect your community, just as he did with bravery and unwavering dedication. Join us in ensuring that Officer Diamond's legacy of service and sacrifice continues to inspire generations to come.
            </p>

            <p>
                Make a Difference: Join the Pomona Police Department

                Take the first step towards a career of honor and service. Join the Pomona Police Department and become part of a team dedicated to upholding justice, protecting lives, and making a positive impact every day. Together, we can continue Officer Diamond's legacy of bravery and ensure that his sacrifice was not in vain.
            </p>

            <p>
                Are You Ready to Serve?

                The call to serve is a noble one, filled with challenges and rewards. Are you ready to stand with us? Join the Pomona Police Department and be a hero in your community, just like Officer Shaun Diamond. The badge awaits those who are brave enough to answer the call
            </p>

            <p>
                Your Future Begins Here. Join Today.

                Note: Inspired by Officer Shaun Diamond's story and the dedication of law enforcement officers everywhere, we invite you to consider a career with Pomona PD. Together, you can help uphold justice and honor those who have sacrificed for our safety.
            </p>


        </div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Diamond;
import React from "react";
import './outbad.scss';
import Dave from "./dave.png";
import Jorge from "./jorge.png";
import Luis from "./luis.png";
import Tone from "./trafficart/tone.png";
import Ttwo from "./trafficart/ttwo.png";
import Tthree from "./trafficart/tthree.png";



function Traffic(){
    return(
    <div className="hpbg">

<div class="ob-grid-container">
    
    <div class="ob-grid-item1"> 
        <div className="ob-card">
        <a href="https://rumble.com/v5c5yz9-traffic-art-prep-1-life-of-mongols-president-kiko-and-jorge-the-rat.html?mref=3tt0vh&mc=3fn9l">
            <div className="ob-card-image">
                <img src={Tone} width="275px" height="300px"></img></div>
        </a>
                <p>TRAFFIC ART PREP 1</p>
                <p>LIFE OF MONGOLS PRESIDENT KIKO & JORGE THE RAT!</p>
                <a href="https://rumble.com/v5c5yz9-traffic-art-prep-1-life-of-mongols-president-kiko-and-jorge-the-rat.html?mref=3tt0vh&mc=3fn9l">WATCH NOW!</a>
                <a href="https://www.atf.gov/careers/becoming-special-agent">JOIN ATF!</a>
        </div>
    </div>

    <div class="ob-grid-item2"> 
        <div className="ob-card">
        <a href="https://rumble.com/v5e2ekc-traffic-art-prep-2-ends-with-surprise-shopping-trip-fbi-in-tow.html?mref=3tt0vh&mc=3fn9l">
            <div className="ob-card-image">
                <img src={Ttwo} width="275px" height="300px"></img></div>
        </a>
                <p>TRAFFIC ART PREP 2</p>
                <p>ENDS WITH SUPRISE SHOPPING TRIP FBI IN TOW!</p>
                <a href="https://rumble.com/v5e2ekc-traffic-art-prep-2-ends-with-surprise-shopping-trip-fbi-in-tow.html?mref=3tt0vh&mc=3fn9l">WATCH NOW!</a>
                <a href="https://fbijobs.gov">JOIN FBI!</a>
        </div>
    </div>

    <div class="ob-grid-item3"> 
        <div className="ob-card">
        <a href="https://rumble.com/v5e2h1e-traffic-art-prep-3-we-cover-more-of-the-fbi-street-arts.html?mref=3tt0vh&mc=3fn9l">
            <div className="ob-card-image">
                <img src={Tthree} width="275px" height="300px"></img></div>
        </a>
                <p>TRAFFIC ART PREP 3</p>
                <p>WE COVER MORE OF THE FBI STREET ARTS AND HOW THEY MAKE COMMON ITEMS MEAN SOMETHING TO YOU</p>
                <a href="https://rumble.com/v5e2h1e-traffic-art-prep-3-we-cover-more-of-the-fbi-street-arts.html?mref=3tt0vh&mc=3fn9l">WATCH NOW!</a>
                <a href="https://fbijobs.gov">JOIN FBI!</a>
        </div>
    </div>





</div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>

    
    </div>
    );
};

export default Traffic;
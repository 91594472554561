import './App.css';
import { useState } from 'react';
import Navbar from './pages/navbar.jsx';
import Homepage from './pages/homepage.js';
import Heroes from './pages/heroes.js';
import Mommy from './pages/mommy.js';
import Fuzz from './pages/fuzz.js';

import Traffic from './pages/traffic.js';
import Outbad from './pages/outbad.js';
import Dave from './pages/dave.js';
import Doc from './pages/doc.js';
import Jorge from './pages/jorge.js';
import Kiko from './pages/kiko.js';
import Luna from './pages/luna.js';
import Nuevo from './pages/nuevo.js';
import Diamond from './pages/diamond.js';
import Shop from './pages/shop.js';
import Thirteen from './pages/thirteen.js';
import Cic from './pages/cic.js';
import Garza from './pages/garza.js';
import Glaze from './pages/glaze.js';
import Laura from './pages/laura.js';
import Toby from './pages/toby.js';
import Luis from './pages/luis.js';
import Rbery from './pages/rbery.png';


import { redirect } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {

  const [isVisible, setIsVisible] = useState(true);

  const handleExit = () => {
    setIsVisible(false);
  };

  return (
    
    <div className="App">
      <Navbar />

    <Router>

        <Routes>
          <Route path="/luis" element={<Luis />} />
        </Routes>


        <Routes>
          <Route path="/" element={<Homepage />} />
        </Routes>
        <Routes>
          <Route path="/index.php" element={<Homepage />} />
        </Routes>
        <Routes>
        <Route path="/shop" element={<Shop />} />
        </Routes>
        <Routes>
          <Route path="/heroes" element={<Heroes />} />
        </Routes>
        <Routes>
          <Route path="/outbad" element={<Outbad />} />
        </Routes>
        <Routes>
          <Route path="/thirteen" element={<Thirteen />} />
        </Routes>
        <Routes>
          <Route path="/traffic" element={<Traffic />} />
        </Routes>





        <Routes>
          <Route path="/guestbook" element={<Homepage />} />
        </Routes>

        <Routes>
          <Route path="/sub/history" element={<Homepage />} />
        </Routes>

        <Routes>
          <Route path="/welcome" element={<Homepage />} />
        </Routes>

        <Routes>
          <Route path="/guestbook" element={<Homepage />} />
        </Routes>


        <Routes>
          <Route path="/events" element={<Homepage />} />
        </Routes>

        <Routes>
          <Route path="/mommy" element={<Mommy />} />
        </Routes>
        <Routes>
          <Route path="/fuzz" element={<Fuzz />} />
        </Routes>
        <Routes>
          <Route path="/cic" element={<Cic />} />
        </Routes>
        <Routes>
          <Route path="/garza" element={<Garza />} />
        </Routes>
        <Routes>
          <Route path="/diamond" element={<Diamond />} />
        </Routes>
        <Routes>
          <Route path="/luna" element={<Luna />} />
        </Routes>
        <Routes>
          <Route path="/nuevo" element={<Nuevo />} />
        </Routes>
        <Routes>
          <Route path="/glaze" element={<Glaze />} />
        </Routes>
        <Routes>
          <Route path="/laura" element={<Laura />} />
        </Routes>

        <Routes>
          <Route path="/dave" element={<Dave />} />
        </Routes>
        <Routes>
          <Route path="/doc" element={<Doc />} />
        </Routes>
        <Routes>
          <Route path="/jorge" element={<Jorge />} />
        </Routes>
        <Routes>
          <Route path="/kiko" element={<Kiko />} />
        </Routes>


        <Routes>
          <Route path="/toby" element={<Toby />} />
        </Routes>

        <Routes>
          <Route path="*" redirect={<Homepage />}/>
        </Routes>

    </Router>

    {isVisible && (

      
<div className="atm2">
<div className="atm">

 <div className="btne"><button onClick={handleExit} className="btncss">EXIT</button></div>

 <iframe class="rumble" width="640" height="360" src="https://rumble.com/embed/v5czjpl/?pub=3tt0vh" frameborder="0" allowfullscreen></iframe>
<p>
</p><a className='shittalk' href="sms:+13232879159">TXT ME DON'T COP OUT +1 (323) 287-9159</a><p>
</p><a className='shittalk' href="mailto:dave@mongolsmc.com">EMAIL ME DON'T COP OUT</a><p>
<p>

Why this happened - JORGE AND GUASSON both operatives of CARTEL JALISCO in SINALOA TERRITORY snitched on Me (Wore a Wire Discussing Things) on El Rayder And El Marcos (The Pizza Guy Right?) This will be his story about why he wore an FBI wire and discussed Marcos 
the night he held the meeting at his house (2021) and his sister arrived some brothers will remember him abruptly walking outside and seeing me at the gate. This motherfucker must have
used to much catnip and thought it's a bright idea to do these things to Los armanos con mas verga de que el diablo tiene 🐸 and El Mayo's Line but this
shit will catch up. For more information on this squeeze Guasson he will understand this fuckup was major. The Federals on the plaza will verify I was under investigation as the
Americans have to share this info inside mexico. Anyone important on that plaza all police etc know im 100. But I don't shit on good people we will
leave this topic alone. I was an intended target of the 2021 rico and these two rejects were ready willing and able to not only gas me up but there own club brother KIKO had he have not been killed was 
certainly targeted by these guys the only blessing to the club and society in general being his death before the FEDS made another grammy award winning MONGOL on the witness stand.
</p>

<p>
The impact of this event - include cnn links and impact info 
</p>

<p>
Decisevly the Three Stooges here Kiko(DEAD) Jorge and Guasson can be thanked today for the 15,000 websites that now link to our cause of recruiting
police officers. For a better understanding of the damage caused look at your hood fame now dangling from my balls.
</p>

<div className="jlink">
<a className="jjlink" href="https://www.cnn.com/2015/05/18/us/dangerous-biker-gangs/index.html">CNN NEWS ARTICLE 1   </a>
<a className="jjlink" href="https://edition.cnn.com/2015/05/18/us/dangerous-biker-gangs/index.html">CNN NEWS ARTICLE 2</a>
<a className="jjlink" href="https://cnnespanol.cnn.com/2015/05/19/clubes-de-motociclistas-o-crimen-organizado-las-bandas-de-motociclistas-mas-peligrosas/">CNN NEWS ARTICLE 3</a>
</div>

<p>
The soloution - The soloution to this problem is the offer I have given in this video and nothing else if you want to hide behind the FEDS with your rat shit and
the fact that informants names rarely get brought out in a FED case unless trial that's fine. This won't be fixed until Guasson and Jorge fight me BACK TO BACK 3 ROUNDS 2 MINUTES EACH 
if they dont beat me I get right's to the video(IT WILL BE RECORDED) otherwise the club get's the right's and hoodfame off the bid. The location CULIACAN SINALOA this way 
if you want to play games Zambadas people will wipe your asses off the plaza and hand you over to your masters the FEDS. 
</p>

<a className='shittalk' href="https://www.independent.co.uk/news/world/americas/sinaloa-cartel-mexico-surfers-murdered-b2542405.html">ARTICLE ON SINALOA CARTEL HANDING OVER KILLERS OF AMERICANS IN ENSENADA</a>


<p>
The Goal Now - police recruiting which the MONGOLS MC seems to actively supprt based on the members that caused this downfall having two Ex Presidents accused of the same.
Now the only club in history to lose it's personal website over an event of snitching. Will the club allow two shitbags to drag 
an entire club down? Or will JORGE AND GUASSON take their fucking skirts off and meet me in CULIACAN SINALOA so we can settle this issue and return 
to normal. If Guasson and Jorge were tortured the confession would make the MAFIA BLUSH and this issue would be much more understood. To the brothers
that were at the house of Jorge the night he left and his SISTER came home his entire house was WIRED UP HAD BEEN and STILL IS and he was returning from 
doing a drive by to Carlos's house so I would notice him and come discuss things with him the next day he wished to meet at McDonalds to set me up 
for the FED's. If you think I am not under Federal Investigation call me and leave a message and see if the FBI contacts so you and the club
can get to the bottom of this mess. 
</p>

<p>
<h2>JORGE & GAUSSON ENJOY THE NEXT ROSARITO MEET</h2>
<h3>FEEL SAFE YOU COST THE WHOLE CLUB</h3>

</p>

<p>
<h1>COMPLAINT LINE JORGE'S FAMILY CONTACTS BELOW</h1>


<ul>
        <li><span className='famnames'>FAM JAZ</span></li>
        <li><a href="sms:4087945194" className='famnum'>(408) 794-5194</a></li>

        <li><span className='famnames'>FAM MAMMY</span></li>
        <li><a href="sms:4083949346" className='famnum'>(408) 394-9346</a></li>

        <li><span className='famnames'>FAM MARIA</span></li>
        <li><a href="sms:4088351841" className='famnum'>(408) 835-1841</a></li>

        <li><span className='famnames'>FAM A***</span></li>
        <li><span className='famnum'>Protected</span></li>

        <li><span className='famnames'>FAM ERICA</span></li>
        <li><a href="sms:4085932040" className='famnum'>(408) 593-2040</a></li>

        <li><span className='famnames'>FAM HELEN</span></li>
        <li><a href="sms:4088289301" className='famnum'>(408) 828-9301</a></li>
    </ul>
</p>

<iframe class="rumble" width="640" height="360" src="https://rumble.com/embed/v5dtcmt/?pub=3tt0vh" frameborder="0" allowfullscreen></iframe>
</p><a className='shittalk' href="https://www.elvigia.net/911/2019/4/29/agarran-sujetos-por-robar-menor-327034.html"><img className='dumbass' src={Rbery}></img></a><p>


</p>

</div>
</div>
)}        


    </div>
    );


};


export default App;

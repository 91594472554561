import React from "react";
import './shop.scss';

function Shop(){
    return(
       
    <div className="sp">

        <p>shop</p>



        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Shop;
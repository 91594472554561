import React from "react";
import './dave.scss';

function Dave(){
    return(
       
    <div className="dp">
<div className="dpp">
        <h1>Profile of Former Mongols MC President: A Cautionary Tale</h1>

        <div className="dvid"><iframe width="900" height="500" src="https://www.youtube.com/embed/c3blDBnA9nM?si=Zr7MvgLiEe-NHKiU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

        Former President of the Mongols MC

<p>
Lil Dave Santillan was once a prominent figure within the Mongols Motorcycle Club (MC), holding the esteemed position of President. With a background steeped in the club’s history, he played a significant role in its operations and image. His tenure was marked by a combination of leadership and influence, shaping the direction of the club and its activities.
</p>
<p>
Despite his prominent position, Lil Dave Santillan's time with the Mongols MC came to an abrupt and controversial end. His departure was primarily due to allegations of serious misconduct and criminal activities that went against the core values and operational standards of the club. The nature of these allegations included not only internal disputes and breaches of club rules but also involvement in activities that drew significant legal scrutiny and jeopardized the club's reputation.
</p>
<p>
The circumstances surrounding Lil Dave Santillan’s expulsion highlight a critical lesson about the consequences of engaging in criminal behavior. His downfall serves as a powerful reminder of how a life steeped in criminality can lead to personal and professional ruin, regardless of one’s status or past achievements. The legal and personal repercussions he faced underscore the dangers of such a lifestyle and the importance of adhering to a path of integrity and lawful conduct.
</p>
<p>
The story of Lil Dave Santillan is more than just a tale of an individual’s rise and fall; it is a cautionary example for anyone who might be tempted to stray into criminal activities. His experience demonstrates how involvement in crime can unravel even the most seemingly secure positions and lead to a cascade of negative consequences, including legal troubles, damaged relationships, and loss of respect.
</p>
<p>
For those considering a similar path, it is essential to understand the profound risks and ethical implications of engaging in criminal behavior. There are numerous positive and lawful avenues available that can provide fulfillment and success without the associated risks of a criminal lifestyle. Pursuing legitimate opportunities not only avoids the perilous consequences but also fosters a more stable and respectful future.
</p>
<p>
The story of Lil Dave Santillan serves as a poignant reminder that no matter how high one may rise, the foundations built on criminality are inherently unstable. True success and respect are earned through lawful actions, ethical behavior, and personal integrity. Let his experience be a guidepost towards making choices that build a positive and enduring legacy rather than one marred by criminal activity.
</p>

        
        

        </div>
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Dave;
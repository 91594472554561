import React from "react";
import './toby.scss';

function Toby(){
    return(
       
    <div className="tp">


        <div className="tpp">
            <h1 className="th">
            Former President of the Mongols MC

            </h1>

<p>
Toby Mitchell, once the influential and feared President of the Mongols Motorcycle Club (MC), was a towering figure in the world of organized crime. With a background that included not only leadership within the Mongols but also a stint as a kickboxer, Mitchell was known for his aggressive demeanor and public persona. His rise through the ranks of the criminal underworld was marked by both his audacious lifestyle and high-profile confrontations.
</p>

<p>
Mitchell’s career took a dramatic turn when he was ousted from his position as the Mongols’ leader in a stunning coup. On April 1, he was summoned to a meeting at the club’s Port Melbourne clubhouse, where he was shockingly informed that he was being removed from power. The situation quickly escalated as he was forced to have his Mongols tattoos removed, a symbol of his expulsion and a clear message that he was no longer part of the gang’s inner circle.
</p>
<p>
The reasons behind his expulsion remain murky but are thought to involve a mix of personal conflicts and high-profile criminal activities that attracted unwanted attention from law enforcement. Mitchell’s downfall was further compounded by his public struggles, including repeated legal issues and violent incidents.
</p>
<p>
Mitchell’s public image took a hit with a highly embarrassing incident where he was knocked out by a homeless man during a street fight. This altercation, captured on CCTV, showed Mitchell—fresh from a boozy night out—initiating a confrontation with a seemingly ordinary individual. The fight ended with Mitchell being knocked to the ground by a well-placed punch from the homeless man, an incident that was widely circulated and ridiculed.
</p>
<p>
The spectacle of a supposedly formidable bikie boss being laid out by a stranger was a stark portrayal of the volatility and unpredictability of the criminal lifestyle. It also underscored Mitchell’s own diminishing prowess and the broader consequences of living a life of violence and crime.
</p>
<p>
Mitchell’s criminal activities have led to multiple legal issues and personal setbacks. His involvement in violent incidents, including a significant brawl in South Yarra and various public altercations, has resulted in ongoing legal battles and public scorn. His history of violent crime includes multiple shootings and stints in prison, reflecting the severe and often irreversible consequences of a criminal lifestyle.
</p>
<p>
Toby Mitchell’s story serves as a potent warning against the allure of a life entrenched in crime and violence. His rise and fall highlight the inherent instability and danger of criminal activities. Despite his initial status and power, Mitchell’s life has been marked by constant legal trouble, public embarrassment, and personal suffering.
</p>
<p>
The brutal reality is that a life of crime often ends in severe personal and legal consequences. The glamorous image of a criminal lifestyle is overshadowed by the risks of violence, imprisonment, and public humiliation. For young people considering such a path, Mitchell’s experiences should serve as a stark reminder of the pitfalls and long-term damage that come with a criminal lifestyle.
</p>
<p>
Toby Mitchell’s trajectory from a powerful bikie leader to a figure of public derision underscores the harsh realities of living outside the law. His story illustrates that criminal behavior leads to profound and often irreversible consequences, not just for oneself but for those around you. It is a clear message that pursuing a life of crime offers only temporary rewards and permanent risks. Choosing a path of legality and integrity provides a more stable and respectful future, free from the dangers that Mitchell's life ultimately exposed.
</p>

        </div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Toby;
import React from "react";
import './mommy.scss';



function Mommy(){
    return(
    <div className="mp">
<div className="mommyp">  
<script async src="https//platform.twitter.com/widgets.js" charset="utf-8"></script>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/i9WkFnePHwM?si=I_Ypt67I7QIQHHId" title="LAPD HERO LILLIAN CARRANZA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>     

<h1 className="lh">
Profile of Lillian L. Carranza: Inspiring Future Police Officers
</h1>

<p>

Lillian L. Carranza stands as a beacon of leadership within the Los Angeles Police Department (LAPD), boasting over three decades of unwavering dedication and accomplishment in law enforcement. Her journey through various roles showcases a remarkable trajectory from patrol officer to her current esteemed position as Assistant Commanding Officer of Operations in Central Bureau. </p>

<p>

With a career spanning since 1989, Carranza's leadership prowess shines through her numerous command roles, including her recent tenure as Commanding Officer of the Commercial Crimes Division and earlier, overseeing the Gang and Narcotics Division. In each role, she has wielded strategic acumen and a commitment to community engagement, earning accolades for reducing crime and enhancing public safety across diverse neighborhoods of Los Angeles.</p>

<p>

Carranza's professional toolkit is robust, encompassing skills in strategic planning, budget administration, and effective resource allocation. Her influence extends beyond LAPD divisions, fostering cross-agency collaborations and spearheading crime reduction tactics. Fluent in Spanish, she enhances outreach efforts to diverse communities, reinforcing LAPD's commitment to inclusivity and effective policing. </p>

<p>

Beyond her operational achievements, Carranza is a passionate advocate for mentorship and team building. She exemplifies resilience and tenacity, emphasizing efficiency and innovation in her approach to law enforcement challenges. Her dedication to continual learning is evident in her pursuit of advanced degrees and ongoing professional development, setting a high standard for leadership within the force. </p>

<p>

Off-duty, Carranza finds solace in family time, reading, and travel, reflecting a balanced perspective that complements her demanding role. Her presence on social media and active community involvement underscore her commitment to transparency and proactive engagement with the public. </p>

<p>

For young individuals considering a career in law enforcement, Lillian Carranza stands as a compelling role model. Her career trajectory not only highlights the possibilities within LAPD but also underscores the impact of dedicated leadership in shaping safer communities. Through her example, she inspires the next generation to embrace the challenges and rewards of a career in public service, ensuring a legacy of excellence and service in law enforcement.
</p>

</div>

        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    
    
    </div>
    );
};

export default Mommy;
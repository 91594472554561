import React from "react";
import './nuevo.scss';

function Nuevo(){
    return(
       
      
        <div className="vp">


        <div className="vpp">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/BOp109KvadA?si=tHK72YNxZvLBi7ms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <h1 className="vh">
            Profile of Former Sheriff Alex Villanueva: Leadership and Legacy in Law Enforcement
            </h1>

            <p>
                Alex Villanueva emerges as a pivotal figure in the landscape of law enforcement, renowned for his tenure as Sheriff of the Los Angeles County Sheriff's Department (LASD). Throughout his career, Villanueva exemplified a steadfast commitment to justice, community engagement, and reform within one of the nation's largest sheriff's departments.
            </p>

            <p>
                Villanueva's journey to leadership was marked by a diverse background in law enforcement, including over three decades of service with LASD before assuming the role of Sheriff. His rise to prominence underscored his dedication to public safety and his advocacy for transparency and accountability within the department.
            </p>

            <p>
                During his tenure, Sheriff Villanueva implemented initiatives aimed at enhancing community relations and improving departmental transparency. His leadership was instrumental in implementing reforms aimed at restoring trust and fostering positive relationships between law enforcement and the diverse communities of Los Angeles County.
            </p>

            <p>
                Sheriff Villanueva's impact extended beyond administrative reforms; he prioritized initiatives to address homelessness, mental health challenges, and youth outreach, reflecting his holistic approach to community policing. Under his leadership, LASD expanded programs aimed at diverting individuals from the criminal justice system towards rehabilitation and support services.
            </p>

            <p>
                Notable among Sheriff Villanueva's achievements was his focus on departmental diversity and inclusion, advocating for hiring practices that reflect the community's demographics. His efforts to promote equity within LASD earned him recognition as a leader committed to fostering a more representative and responsive law enforcement agency.
            </p>

            <p>
                In retirement, Sheriff Villanueva continues to be a vocal advocate for law enforcement reform and community empowerment. His legacy includes a comprehensive career that embodies the principles of service, integrity, and compassion, leaving an indelible mark on the LASD and the broader law enforcement community.
            </p>

            <p>
                For aspiring law enforcement professionals and community leaders, Sheriff Villanueva's journey serves as an inspirational testament to the impact of dedicated leadership and progressive reform in shaping safer and more inclusive communities.
            </p>

            <p>
            Former Sheriff Alex Villanueva's leadership and legacy within the Los Angeles County Sheriff's Department reflect a commitment to public service that continues to resonate, inspiring future generations to uphold the highest standards of justice and community service.
            </p>

        </div>
    </div>

    );
};

export default Nuevo;
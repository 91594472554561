import React from "react";
import './kiko.scss';
import KIKOG from './mexico/kikog.jpg';
import JALISCO from './mexico/jalisco.jpg';
import MEKIKO from './mexico/mekiko.jpg';
import TOTS from './mexico/tots.png';






function Kiko(){
    return(
       
    <div className="kpp">

          
        <p className="kp">
        <h1 className="kh">LUIS PADILLA AKA "KIKO" - YET ANOTHER MONGOL SNITCH "DEAD" </h1>  


        <iframe class="rumble" width="640" height="360" src="https://rumble.com/embed/v59tqjh/?pub=3tt0vh" frameborder="0" allowfullscreen></iframe>




<p>
Luis Javier Padilla AKA “KIKO” was the president of the mongols motorcycle club in Mexico over all of the chapters of the club. While robbing children <a className="k" href="https://www.elvigia.net/911/2019/4/29/agarran-sujetos-por-robar-menor-327034.html">LINK HERE</a> he was in charge of the entire club for the country of Mexico. As you can see from the BLACK RAIN indightment child abuse is a thing with MONGOL LEADERS. Doc Cavazos the EX PRESIDENT used a TOYS FOR TOTS TOY DRIVE to praise MONGOLS for attacking people. His time with the club and as Club President is very different from what people like Lisa Ling and the common college student or citizen would think of the life of a person with this title as I am hesitent to use the term “STATURE” you will see why in this profile of him. While at this point with the two preceding presidents of the club being expelled for being alleged informants this should not come to your suprise Kiko was nothing more than a cuckservient bird watcher trying to save his own ego from being demolished by the FBI and trying at all costs to hang onto his false image that propped him up emotionally. 
</p>
<p className="imgk">
<img className="imgkk" src={TOTS}></img>
</p>

<p>
Kiko become a member of the motorcycle club joining the club in Montebello California.  Chapter 13[1] has high signifigance within club. Forever to now be lost with advertisements for Montebello Police department because of the snitching of KIKO and his friends JORGE AND GUASSON of ENSENADA. At the time of joining the club KIKO was living in the United States as an illegal immigrant something he never forgot as in the end he clutched to the dream of coming back to the United States through being an informant which ultimatley led to his death not by the club but by the CARTEL. Simply put he got caught lackin at MARCO’S PIZZA and liquidated by SINALOA. GOD PRAISE THEM AND THE SHOOTER FOR TAKING THIS TRASH OUT. KIKO’S downfall with the club began when the venerable and legendy AGENT JOHN CICCONE of the ATF began to build a rico case on KIKO in the BLACK RAIN CASE three-year undercover investigation in which federal agents infiltrated the gang, resulting in an 86-count indictment. Research of this case will show you KIKO’s real name and in fact that his was such a piss poor criminal he told his co defendent RODRIGUEZ he had cocaine arriving from Sinaloa giving up his supply chain volountarily. I go into this more in depth on my rumble video series “TRAFFIC ART” linked here!
</p>

<p className="imgk">
<img className="imgkk" src={JALISCO}></img>
</p>
<p>Kiko's wife admitting he worked with JALISCO CARTEL</p>

<p className="imgk">
<img className="imgkk" src={MEKIKO}></img>
</p>
<p>Me with KIKO I spent nearly every day with him until his death</p>


<p className="imgk">
    <img className="imgkk" src={KIKOG}></img>
</p>
<p>Me with KIKO and the LOW PRICED SNITCH GUASSON</p>


<p>
BLACK RAIN INDICTMENT PG 53 ARTICLE 227 LINE 16-18
</p>

<p>
227. On September 21, 2007, by telephone using coded
16 language, defendant L. PADILLA told defendant RODRIGUEZ that L.
17 PADILLA’s source of supply would be transporting cocaine into the
18 United States from Sinaloa, Mexico.
</p>

<p>
BLACK RAIN INDICTMENT PG 56 LINE 248
</p>

<p>
248.  On October 22, 2007, by telephone using coded
language, defendant RODRIGUEZ told defendant L. PADILLA that
RODRIGUEZ had been offered cocaine for $16,000 per kilogram, and
L. PADILLA stated that “Bird” had been selling low quality
narcotics but that L. PADILLA’s source would be transporting
narcotics from Sinaloa, Mexico, that weekend.
</p>

<p>
BLACK RAIN INDICTMENT PG 59-60 LINE 26-28 
</p>

<p>
26 270. On December 1, 2007, by telephone using coded
27 language, defendant L. PADILLA told defendant RODRIGUEZ that L.
28 PADILLA had narcotics for RODRIGUEZ and that RODRIGUEZ should
1   call him on a different telephone.
</p>

<p>
BLACK RAIN INDICTMENT PG 61 LINE 7-12
</p>

<p>
7 281. On December 13, 2007, defendant RODRIGUEZ possessed a
8 loaded Glock Model 30 .45 caliber semi-automatic handgun, a Llama
9 .45 caliber semi-automatic handgun, a Glock 9 mm handgun and
10 Mongols clothing, and defendant L. PADILLA delivered
11 approximately 112 grams of methamphetamine to RODRIGUEZ and
12 defendant TRUJILLO at their residence in Arcadia, California.
</p>

<p>
BLACK RAIN INDICTMENT PG 61 LINE 13-16 
</p>

<p>
13 282. On December 13, 2007, by telephone using coded
14 language, defendant L. PADILLA told defendant RODRIGUEZ that L.
15 PADILLA had been arrested and asked RODRIGUEZ to meet with him,
16 but to sneak him in through a rear entrance.
</p>

<p>
BLACK RAIN INDICTMENT PG 61 LINE 21-26
</p>

<p>
21 284. On December 14, 2007, by telephone using coded
22 language, defendants MEDEL and FIGUEROA discussed the fact that
23 FIGUEROA had been arrested with a weapon and scale at his
24 residence and that FIGUEROA was the suspect in a murder
25 investigation, and MEDEL told RODRIGUEZ that defendant L. PADILLA
26 had also been arrested with narcotics.
</p>

<p>
BLACK RAIN INDICTMENT PG 94 LINE 4-10
</p>
<p>
4 52. On or about December 13, 2007, in Los Angeles County,
5 within the Central District of California, defendants RODRIGUEZ,
6 L. PADILLA, and TRUJILLO knowingly and intentionally possessed
7 with the intent to distribute methamphetamine, a schedule II
8 controlled substance, in violation of Title 21, United States
9 Code, Section 841(a)(1).
10 Racketeering Act Forty-Nine
</p>



<p>
Which any normal citizen at this point can begin to understand the level of intelligence of MONGOL LEADERS. Contrary to popular belief These guys never learned anything from the J Edgar Hoover days of the FBI when they were bugging MLK’s lines and houses. It is also important to understand KIKO was never caught delivering cocaine because he was a broke bitch at the time and merely middle manned the product to gain some procedes for his meth ditrubution which by Mexican standards is heinous to call a distrubution operation like frequently seen busted by LAPD HERO LILLIAN CARRANZA[3]. In the end KIKO did not actually work with the SINALOA CARTEL but against them snitching on there operatives in ENSENADA MEXICO and working for JALISCO CARTEL[4]. 
</p>

</p>






        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>



    </div>
    );
};

export default Kiko;
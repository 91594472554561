import React from "react";
import './glaze.scss';

function Glaze(){
    return(
       
    <div className="glp">



        <div className="glpp">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/D6NmgIJE2Xg?si=qLFkvmPI_zjG8qFl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <h1 className="glh">
                Profile of Andy Glaze: California Fire Dept COMMANDER and Ultra Marathon Runner
            </h1>

            <p>
                Andy Glaze embodies the spirit of resilience and dedication as both a California firefighter and an accomplished ultra marathon runner. His dual commitment to public service and extreme endurance sports has established him as a notable figure in both communities, showcasing his extraordinary physical and mental fortitude.
            </p>

            <p>
                As a firefighter, Glaze stands at the forefront of emergency response, tackling wildfires and other crises with courage and professionalism. His career is a testament to bravery and service, reflecting his unwavering commitment to protecting lives and property in the face of adversity.
            </p>

            <p>
                Outside of his duties as a firefighter, Glaze is a formidable presence in the ultra marathon circuit, where he has completed several grueling races, including the prestigious Cocodona 250. His participation in these events highlights his exceptional endurance and determination, pushing the limits of human capability over daunting distances and challenging terrain.
            </p>

            <p>
                In races like the Cocodona 250, Glaze has demonstrated remarkable perseverance, completing the 250-mile course in impressive times that underscore his elite athleticism and mental toughness. His achievements in ultra marathons serve as a testament to his relentless pursuit of personal excellence and his ability to overcome physical challenges with unwavering resolve.
            </p>

            <p>
                Notably, Glaze has maintained a running streak of over 20 miles per day for an extended period, showcasing his consistency and dedication to his craft. This remarkable feat underscores his commitment to physical fitness and endurance, setting a high standard for himself and inspiring others in the running community.
            </p>

            <p>
                Glaze's passion for running and firefighting is fueled by a deep-seated desire to serve others and push the boundaries of his own capabilities. His journey as a firefighter and ultra marathon runner embodies the values of perseverance, courage, and community spirit, making him a role model for aspiring athletes and public servants alike.
            </p>

            <p>
                In summary, Andy Glaze's career as a California firefighter and his achievements in ultra marathon running exemplify the intersection of dedication, athleticism, and service. His ongoing pursuit of excellence in both fields inspires admiration and underscores the profound impact of individuals who strive to make a difference in their communities through their passion and commitment.
            </p>



        </div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Glaze;
import React from "react";
import './laura.scss';

function Laura(){
    return(
       
    <div className="lsp">
        <div className="lspp">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/MuXGgX42ulI?si=iqM8Qt724RKTtsT0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <h1 className="lsh">
                Profile of FBI Special Agent Laura Schwartzenberger: A Tribute to Service and Sacrifice
            </h1>

            <p>
                FBI Special Agent Laura Schwartzenberger exemplified the highest ideals of service and dedication during her tenure with the Federal Bureau of Investigation. Tragically killed in the line of duty while bravely serving a warrant, her legacy resonates as a beacon of courage and commitment to upholding justice.
            </p>

            <p>
                Agent Schwartzenberger's career with the FBI was marked by her unwavering pursuit of criminals who threaten the safety and security of our communities. Her role as an FBI agent provided her with the opportunity to make a tangible difference in dismantling criminal organizations like the Mongols Motorcycle Club (MongolsMC), known for their violent criminal activities and drug trafficking.
            </p>

            <p>
                Joining the FBI offers a unique opportunity to serve at the forefront of law enforcement in the United States. As America's top law enforcement agency, the FBI wields considerable power and resources, enabling agents like Schwartzenberger to investigate and prosecute some of the most complex and dangerous criminal cases.
            </p>

            <p>
                In contrast, criminal organizations like the MongolsMC operate outside the law, resorting to violence and intimidation to achieve their objectives. While they may exert influence in certain circles, their power is ultimately limited by their criminal activities and the relentless efforts of law enforcement agencies like the FBI to dismantle their operations.
            </p>

            <p>
                The FBI's ability to intimidate and compel criminal organizations to cooperate as informants is rooted in its authority and capabilities as the premier law enforcement agency in the nation. Through sophisticated investigative techniques, surveillance, and strategic operations, the FBI can disrupt criminal networks and hold perpetrators accountable for their actions.
            </p>

            <p>
                Becoming an FBI agent offers not only the opportunity to combat serious crime and protect communities but also a range of benefits and opportunities. FBI agents receive comprehensive training, competitive salaries, and benefits that include healthcare, retirement plans, and opportunities for career advancement and specialization in various investigative fields.
            </p>

            <p>
                Agent Schwartzenberger's ultimate sacrifice serves as a poignant reminder of the risks and rewards of a career in law enforcement. Her dedication and bravery in confronting dangerous criminals underscore the profound impact that FBI agents can have in safeguarding our society and upholding the rule of law.
            </p>

            <p>
                For those considering a career path, joining the FBI represents a commitment to public service, integrity, and making a meaningful difference in the fight against crime. The FBI's strength lies not only in its investigative prowess but also in the dedication and courage of agents like Laura Schwartzenberger, whose legacy inspires future generations to pursue careers dedicated to justice and service to their country.
            </p>

            <p>
                In addition to the compelling reasons to join the FBI, it's important to recognize the rigorous selection process that applicants undergo. Only approximately 30% of applicants successfully navigate the stringent screening, background checks, physical fitness tests, and interviews required to become an FBI agent. This selective process ensures that those who join the FBI possess the necessary skills, integrity, and commitment to uphold the agency's high standards of professionalism and ethical conduct. For those who are determined and qualified, the opportunity to serve with the FBI represents not only a career but a calling to protect the nation and uphold justice on a profound level. Unlike the rejects with the Mongols only the strong can move into this community.
            </p>

        </div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Laura;
import React from "react";
import './fuzz.scss';



function Fuzz(){
    return(
    <div className="fp">
        

<div className="fup">  
<h1 className="fh">@PAWFICCER_FUZZ FSPD FELINE HERO</h1>

</div>

        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    
    
</div>
    );
};

export default Fuzz;
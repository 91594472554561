import React from "react";
import './thirteen.scss';
import { useState } from "react";



function Thirteen(){



    return(
       


    <div className="thirtp">
        <div className="thirtpp">
        <iframe class="rumble" width="640" height="360" src="https://rumble.com/embed/v5byjf1/?pub=3tt0vh" frameborder="0" allowfullscreen></iframe>
<p className="red">
<center>
AT THIS TIME WE ARE ONLY SENDING OUR PATCH HOLDER 

DREAM CATCHERS 4 TIMES PER YEAR 

YOUR ORDER MIGHT TAKE 3 MONTHS TO ARRIVE
</center>
</p>

<p className="tt">
Support Our Mission: Empower the Next Generation with the Mongols MC Police and Bird Watching Club
</p>

<p>
At the Mongols MC Police and Bird Watching Club, we are on a mission to inspire, educate, and create positive change in our communities. Our unique charity combines two powerful focuses: guiding young people towards careers in law enforcement and raising awareness about the dangers of criminal activities, including the allure of criminal organizations such as the Mongols Motorcycle Club.
</p>

<p className="ttt">
Why We Need Your Support
</p>

<p>
Our organization is dedicated to fostering the next generation of heroes. By providing educational programs and mentorship, we aim to prepare young people for careers in policing and instill in them a deep understanding of the responsibilities and challenges associated with law enforcement. Simultaneously, we work to inform them about the pitfalls of crime and the harmful impact of joining criminal gangs.
</p>

<p>
Your generous donations are vital to our continued success and impact. Each contribution helps fund crucial programs, such as workshops, mentorship sessions, and community outreach events, which are essential for guiding youth towards positive paths and understanding the significance of their choices.
</p>

<p className="ttt">
How Your Donation Makes a Difference
</p>

<p>
When you donate $69 to our cause, you are not only supporting our mission but also receiving a token of our appreciation. As a thank-you for your generosity, we will send you a beautiful dream catcher. This handcrafted item symbolizes protection and guidance, reflecting the essence of our work in steering young people towards bright futures.
</p>

<p className="ttt">
Every dollar donated goes directly towards:
</p>

<p>
Educational Workshops: Providing hands-on learning experiences about law enforcement careers.
Mentorship Programs: Connecting young individuals with experienced professionals who can offer guidance and support.
Community Outreach: Organizing events to raise awareness about the dangers of crime and the impact of criminal groups.
Support Resources: Developing materials and resources that help educate and motivate youth to pursue positive paths.
</p>

<p className="ttt">
Become a Full Patch Member of Chapter 13
</p>

<p>
As part of our commitment to fostering leadership and community involvement, we offer an exclusive opportunity for our dedicated supporters. By contributing to our cause, you can express your interest in becoming a full patch member of Chapter 13 of the Mongols MC Police Recruiting and Bird Watching Club. This prestigious membership not only signifies your deep commitment to our mission but also connects you with a network of like-minded individuals who share your passion for making a difference.
</p>

<p className="ttt">
Join Us in Creating America’s Next Line of Heroes
</p>

<p>
Your support plays a crucial role in our efforts to shape a safer and more informed future. By contributing to the Mongols MC Police and Bird Watching Club, you are investing in the potential of young people and helping to create a new generation of leaders who are committed to making a positive impact in their communities.
</p>

<p className="tt">
To make a donation and receive your dream catcher, or to learn more about becoming a full patch member of Chapter 13, click this link  or contact us directly at FBI@MONGOLSMC.COM. Every contribution, big or small, brings us one step closer to our goal of empowering young people and fostering a safer, more enlightened society.
</p>


<p>
Thank you for your generosity and for standing with us in this important mission. Together, we can make a difference and build a brighter future for our youth.
</p>


        
        </div>

        <div className="spacer"></div>
        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
        
    </div>
    );
};


  
var x = document.getElementById("atm2");

export default Thirteen;
import React from "react";
import './garza.scss';

function Garza(){
    return(
       
    <div className="gp">


        <div className="gpp">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/taNkH9QFmYQ?si=_9qNB-hE9MBsakv5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <h1 className="gh">
            Profile of Officer Garza: Upholding Safety and Service with LAPD Motorcycle Forces
            </h1>

            <p>
            Officer Garza stands at the forefront of safety and service within the Los Angeles Police Department (LAPD) Motorcycle Forces, where his career is marked by a steadfast commitment to public safety and efficient law enforcement. With a distinguished tenure in the force, Garza has earned a reputation for his exceptional skill in motorcycle patrol and his unwavering dedication to protecting the community.
            </p>

            <p>
            Garza's career is punctuated by his pivotal role in enforcing traffic regulations and ensuring the smooth flow of urban mobility. His mastery of motorcycle maneuvering through Los Angeles' bustling streets and neighborhoods has been crucial in responding swiftly to emergencies and maintaining order during major events.
            </p>

            <p>
            A highlight of Garza's achievements includes his leadership in high-profile operations such as managing traffic for large-scale public gatherings and events. His strategic deployment of motorcycle units has not only enhanced traffic management but also contributed significantly to reducing accidents and promoting compliance with traffic laws across diverse communities within the city.
            </p>

            <p>
            As a respected member of the LAPD Motorcycle Forces, Garza embodies professionalism and dedication in every aspect of his service. His role demands not only technical expertise but also empathy and effective communication skills, qualities that Garza consistently demonstrates in his interactions with the public and fellow officers.
            </p>

            <p>
            In contrast to those who disregard traffic laws, Officer Garza represents the values of accountability and responsibility. His career path within the LAPD provides him with comprehensive benefits, including healthcare coverage, retirement planning, and opportunities for ongoing professional development—a testament to the LAPD's commitment to supporting its officers.
            </p>

            <p>
            For young individuals aspiring to pursue a career in law enforcement with a focus on motorcycle patrol, Officer Garza's journey serves as an inspiring example. His dedication to community safety and his impact on improving urban mobility underscore the rewards of serving the public and upholding the law.
            </p>

            <p>
            Officer Garza's steadfast commitment to safety and service within the LAPD Motorcycle Forces reflects the highest standards of law enforcement professionalism. His legacy will continue to inspire future generations of officers to pursue careers dedicated to protecting and serving the diverse communities of Los Angeles.
            </p>



        </div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Garza;
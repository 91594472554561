import React from "react";
import './outbad.scss';
import Dave from "./dave.png";
import Luna from "./luna.jpg";
import Jorge from "./jorge.png";
import Kiko from "./kiko.png";
import Fbi from "./fbi.png";
import Toby from "./toby.png";
import Luis from "./luis.png";
import Doc from "./doc.png";



function Outbad(){
    return(
    <div className="hpbg">

<div class="ob-grid-container">
    
    <div class="ob-grid-item1"> 
        <div className="ob-card">
        <a href="./dave">
            <div className="ob-card-image">
                <img src={Dave} width="275px" height="300px"></img></div>
        </a>
                <p>CLUB PRES & SNITCH</p>
                <p>SOLD HIS PUSSY TO (ATF) JOHN CICCONE</p>
                <a href="./dave">(PRES) LIL DAVE</a>
                <a href="https://www.atf.gov/careers/becoming-special-agent">JOIN ATF!</a>
        </div>
    </div>

    <div class="ob-grid-item2"> 
        <div className="ob-card">
        <a href="./luis">
            <div className="ob-card-image">
                <img src={Luis} width="275px" height="300px"></img></div>
        </a>
                <p>LUIS HERNANDEZ "PINCHE GUASSON"</p>
                <p>FBI SLUT & SNITCHED ON SINALOA CARTEL</p>
                <a href="./luis">PINCHE GUASSON</a>
                <a href="https://fbijobs.gov">JOIN FBI!</a>
        </div>
    </div>

    <div class="ob-grid-item3"> 
        <div className="ob-card">
        <a href="./jorge">
            <div className="ob-card-image">
                <img src={Jorge} width="275px" height="300px"></img></div>
        </a>
                <p>JORGE BUSTOS</p>
                <p>FBI SLUT & SNITCHED ON SINALOA CARTEL</p>
                <a href="./jorge">JORGE BUSTOS</a>
                <a href="https://fbijobs.gov">JOIN FBI!</a>
        </div>
    </div>

    <div class="ob-grid-item4"> 
        <div className="ob-card">
        <a href="./kiko">
            <div className="ob-card-image">
                <img src={Kiko} width="275px" height="300px"></img></div>
        </a>
                <p>(MEX) CLUB PRES KIKO</p>
                <p>FBI SLUT & SNITCHED ON SINALOA CARTEL</p>
                <a href="./kiko">(KIKO) LUIS PADILLA</a>
                <a href="https://www.montebelloca.gov/departments/police/about/join_montebello_pd">JOIN MONTEBELLO PD!</a>
        </div>
    </div>

    <div class="ob-grid-item5"> 
        <div className="ob-card">
        <a href="./toby">
            <div className="ob-card-image">
                <img src={Toby} width="275px" height="300px"></img></div>
        </a>
                <p>(AUS) EX CLUB PRES </p>
                <p>SNITCH KNOCKED TF OUT BY HOMELESS MAN!</p>
                <a href="./toby">TOBY MITCHELL</a>
                <a href="https://fbijobs.gov">JOIN FBI!</a>

        </div>
    </div>
    
    <div class="ob-grid-item6"> 
        <div className="ob-card">
        <a href="./doc">
            <div className="ob-card-image">
                <img src={Doc} width="275px" height="300px"></img></div>
        </a>
                <p>Profile of Ruben Cavazos: A Cautionary Tale of Leadership and Downfall</p>
                <p>PRESIDENT PRECEEDING LIL DAVE TURNED INFORMANT</p>
                <a href="./doc">DOC CAVASOS</a>
                <a href="https://www.atf.gov/careers/becoming-special-agent">JOIN ATF!</a>

        </div>
    </div>



</div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>

    
    </div>
    );
};

export default Outbad;
import React from "react";
import './Luna.scss';

function Luna(){
    return(
       
    <div className="lup">


        <div className="lupp">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/BOp109KvadA?si=tHK72YNxZvLBi7ms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            <h1 className="luh">
                Profile of Sheriff Robert G. Luna: Leading with Integrity and Community Focus
            </h1>

            <p>
                Sheriff Robert G. Luna stands as a pillar of leadership within the Los Angeles County Sheriff's Department (LASD), distinguished by his unwavering commitment to public service, integrity, and community engagement. With a career spanning decades within LASD, Sheriff Luna has risen through the ranks, embodying the values of accountability and transparency in law enforcement.
            </p>

            <p>
                Sheriff Luna's journey to leadership began with his early years in patrol and investigative roles, where he honed his skills and gained firsthand experience in the complexities of law enforcement. His ascent to the position of Sheriff has been marked by a steadfast dedication to improving public safety and enhancing the trust between law enforcement and the diverse communities of Los Angeles County.
            </p>

            <p>
                Under Sheriff Luna's leadership, LASD has implemented progressive reforms aimed at fostering accountability and strengthening community relations. His initiatives include enhancing transparency within the department, promoting diversity in hiring practices, and prioritizing mental health and crisis intervention training for deputies.
            </p>

            <p>
                Sheriff Luna's commitment to community-focused policing is evident in his proactive approach to addressing the root causes of crime and enhancing public safety. He has championed programs that support youth development, combat human trafficking, and provide resources for individuals struggling with homelessness and mental health issues.
            </p>

            <p>
                Beyond his administrative responsibilities, Sheriff Luna maintains a hands-on approach to leadership, regularly engaging with community members, stakeholders, and law enforcement partners to address concerns and foster collaboration. His leadership style emphasizes teamwork, professionalism, and a dedication to serving the needs of all residents in Los Angeles County.
            </p>

            <p>
                Sheriff Luna's tenure as Sheriff of LASD exemplifies his dedication to upholding the highest standards of law enforcement while advocating for positive change within the department and the communities it serves. His leadership continues to inspire trust and confidence among deputies and community members alike, setting a standard for excellence in public safety.
            </p>

            <p>
                In summary, Sheriff Robert G. Luna's career with LASD is defined by his commitment to integrity, community-focused policing, and continuous improvement. His leadership reflects a profound dedication to serving and protecting the diverse communities of Los Angeles County, ensuring a safer and more equitable environment for all residents.
            </p>



        </div>


        
        <div className="dayna"><h1>I ♥️ YOU DAYNA!</h1></div>
    </div>
    );
};

export default Luna;